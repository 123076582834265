export default {
    topCategories: 'Top Kategorien',
    register: 'Registrieren',
    login: 'Logge dich ein',
    forgotPassword: 'Passwort vergessen',
    resetPassword: 'Passwort zurücksetzen',
    email: 'Email',
    downloadIOSApp: 'Downloade die iOS App',
    downloadAndroidApp: 'Downloade die Android App',
    ctaSignup: 'CTA jetzt registrieren',
    cookieSettingsButton: 'Button für Cookie Einstellungen',
    signupButton: 'Button zur Registrierung',
    desktopLoginButton: 'Desktop login button',
    viewAll: 'Alles ansehen',
    breadcrumb: 'Navigation für Untermenus',
    mobileMenuToggle: 'Menu Schaltfläche',
    location: 'Ort',
    cookieConsent: {
        customizeCookieConsent: 'Cookies anpassen',
        acceptCookieConsent: 'Cookies akzeptieren',
        backClicked: 'Zurück',
    },
    closeActionSheet: 'action sheet schließen',
    remove: 'Entfernen',
    add: 'Hinzufügen',
    close: 'Schließen',
    socialMediaRow: {
        twitter: 'Twitter',
        facebook: 'Facebook',
        instagram: 'Instagram',
        linkedin: 'LinkedIn',
    },
    dashboard: {
        downloadAndroidApp: 'Downloade die Android App',
        downloadIOSApp: 'Downloade die iOS App',
    },
    searchSheet: {
        loadMore: 'Mehr laden',
    },
    newsLetterSignup: {
        email: 'email',
    },
    myProfile: {
        showMoreReviews: 'Weitere Rezensionen Anzeigen',
    },
    productUpload: {
        currentLocation: 'Aktueller Standort',
        save: 'Speichern',
        deleteLocation: 'Standort löschen',
        addLocation: 'Standort hinzfügen',
        uploadLocation: 'Produkt-Standort',
        getCurrentLocationButton: 'Aktuellen Standort ermitteln',
    },
    voucher: {
        code: 'Gutscheincode',
        submit: 'Gutschein aktivieren',
    },
    requirements: 'Anforderungen',
    showMore: 'Mehr anzeigen',
    sort: {
        default: 'Sortieren nach',
        updatedAt: 'Neuste',
        newest: 'Neuste zuerst',
        price: 'Preis',
        priceAsc: 'Preis niedrig zu hoch',
        priceDesc: 'Preis hoch zu niedrig',
        location: 'Distanz',
    },
    filter: {
        collection: 'Kategorien',
        location: 'Standort suchen',
        priceRange: 'Preisspanne',
        community: 'Community auswählen',
        shipping: 'Mit Versand',
    },
    clear: 'Löschen',
    from: 'Von',
    to: 'Bis',
    pickup: 'Nur Abholung',
    shipping: 'Mit Versand',
    currency: 'Währung',
};
